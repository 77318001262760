export const toggleNavigation = {
    namespaced: true,
    state: {
        isNavigationOpen: true
    },
    mutations: {
        toggleNavigation(state) {
            state.isNavigationOpen = !state.isNavigationOpen;
        }
    },
    actions: {
        toggleNavigation({ commit }) {
            commit('toggleNavigation'); // Call the mutation with the correct name
        }
    },
    getters: {
        isNavigationOpen: state => state.isNavigationOpen
    }
};

