import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_POINT;

// create a new axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 5000, 
});

export default axiosInstance;
