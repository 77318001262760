import axiosInstance from "@/plugins/HTTP";


class AuthService {
  login(user) {
    return axiosInstance
      .post("login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        localStorage.setItem("jwt_token", JSON.stringify(response.data.token));
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("jwt_token");
  }

  register(user) {
    return axiosInstance
      .post("register", {
        username: user.username,
        country: user.country,
        type: user.type,
      })
      .then((response) => {
        return response.data;
      });
  }

  otpVerification(user) {
    return axiosInstance.post("token-verification", user).then((response) => {
      return response.data;
    });
  }


  createPassword(user) {
    return axiosInstance.post("create-password-while-sign-up", user).then((response) => {
      return response.data;
    });
  }


  forgetotpVerification(user) {
    return axiosInstance.post("forget-password-token-verify", user).then((response) => {
      return response.data;
    });
  }

  otpResend(user) {
    return axiosInstance.post("otp-resend", user).then((response) => {
      return response.data;
    });
  }



  forgotPassword(user) {
    return axiosInstance.post("reset-password-token-request", user, {
    });
  }

  resetPassword(user, id, token) {
    return axiosInstance.post("reset-password", {
      id: id,
      token: token,
      password: user.password,
      password_confirmation: user.password_confirmation,
    });
  }
}

export default new AuthService();
