import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/CheckoutLoginView"),
    meta: {
      title: "Checkout",
    },
  },
  {
    path: "/user/checkout/pay",
    name: "usercheckoutpay",
    component: () =>
      import(/* webpackChunkName: "usercheckoutpay" */ "../views/UserCheckoutPayView"),
    meta: {
      middleware: "guest",
      title: "User Checkout Pay",
    },
  },
  {
    path: "/signup/create",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignUpView"),
    meta: {
      title: "Sign Up",
    },
  },
  {
    path: "/otp/verification",
    name: "otpverification",
    component: () =>
      import(/* webpackChunkName: "otpverification" */ "../views/OtpVerify"),
    meta: {
      title: "OTP Verification",
    },
  },
  {
    path: "/create/payment/:hueyiPayResponses",
    name: "HueyiPayResponse",
    component: () =>
      import(/* webpackChunkName: "otpverification" */ "../views/CreatePayment"),
    meta: {
      title: "Hueyipay Payment",
    },
  },
  {
    path: "/forget/otp/verification",
    name: "passwordotpverification",
    component: () =>
      import(
        /* webpackChunkName: "otpverification" */ "../views/PasswordOtpVerify.vue"
      ),
    meta: {
      title: "Password OTP Verification",
    },
  },
  {
    path: "/forget/password",
    name: "forget-password",
    component: () =>
      import(/* webpackChunkName: "forget" */ "../views/ForgetPasswordView"),
    meta: {
      title: "Forget Password",
    },
  },
  {
    path: "/change/password",
    name: "changepassword",
    component: () =>
      import(/* webpackChunkName: "forget" */ "../views/ChangePasswordView"),
    meta: {
      title: "Change Password",
    },
  },

  {
    path: "/password/reset/success",
    name: "savepassword",
    component: () =>
      import(
        /* webpackChunkName: "forget" */ "../views/SavePasswordSuccessView"
      ),
    meta: {
      title: "Change Password",
    },
  },
  {
    path: "/checkout/pay",
    name: "checkout-pay",
    component: () =>
      import(/* webpackChunkName: "checkoutpay" */ "../views/CheckoutView"),
    meta: {
      middleware: "guest",
      title: "Checkout",
    },
  },
  {
    path: "/create/password",
    name: "create-password",
    component: () =>
      import(/* webpackChunkName: "createpassword" */ "../views/CreatePasswordView"),
    meta: {
      title: "Create Password",
    },
  },
  {
    path: "/signup/complete",
    name: "signup-complete",
    component: () =>
      import(/* webpackChunkName: "signupcomplete" */ "../views/SignupCompleteView"),
    meta: {
      title: "Signup Complete",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView"),
    meta: {
      middleware: "guest",
      title: "Dashboard",
    },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/PricingView"),
    meta: {
      middleware: "guest",
      title: "Pricing",
    },
  },
  {
    path: "/subscription",
    name: "subscription",
    component: () =>
      import(/* webpackChunkName: "subscription" */ "../views/SubscriptionView"),
    meta: {
      middleware: "guest",
      title: "Subscription",
    },
  },
  {
    path: "/loggedin/devices",
    name: "devices",
    component: () =>
      import(/* webpackChunkName: "devices" */ "../views/DeviceView"),
    meta: {
      middleware: "guest",
      title: "All Devices",
    },
  },
  {
    path: "/password/change",
    name: "passwordchange",
    component: () =>
      import(/* webpackChunkName: "passwordchange" */ "../views/PasswordChangeView"),
    meta: {
      middleware: "guest",
      title: "Change Password",
    },
  },
  {
    name: "PurchaseSuccess",
    path: "/purchase/success",
    query: {},
    component: () => import("../views/PurchaseSuccessView"),
    meta: {
      middleware: "guest",
      title: "Purchase Success",
    },
  },
  {
    name: "PurchaseCancel",
    path: "/purchase/cancel",
    query: {},
    component: () => import("../views/PurchaseCancelView"),
    meta: {
      middleware: "guest",
      title: "Purchase Cancel",
    },
  },
  {
    name: "PurchaseFailed",
    path: "/purchase/failed",
    query: {},
    component: () => import("../views/PurchaseFailedView"),
    meta: {
      middleware: "guest",
      title: "Purchase Failed",
    },
  },
];

// 创建路由器
const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(),
  routes,
});

// 中间件函数
function guestMiddleware(to, from, next) {
  const jwtToken = localStorage.getItem('jwt_token');
  if (jwtToken) {
    next();
  } else {
    next({ name: "login", query: { from: to.path } });
  }
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 检查路由是否需要 guest 中间件
  if (to.meta.middleware === "guest") {
    guestMiddleware(to, from, next);
  } else {
    const jwtToken = localStorage.getItem('jwt_token');
    if (jwtToken) {
      // 用户已登录
      if (to.name === "home" || to.name === "login") {
        next({ name: "dashboard" });
      } else {
        next();
      }
    } else {
      // 用户未登录
      next();
    }
  }
});

export default router;
