import AuthService from "../services/auth.service";
import router from '@/router';

const initialState = {  user: null };

initialState["userVerifyData"] = null;

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    forgotPassword({ commit }, user) {
      return AuthService.forgotPassword(user).then(
        (user) => {
          commit("registerSuccess");
          return Promise.resolve(user);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      AuthService.logout();
      localStorage.removeItem("panelType");
      commit("logout");
      router.push('/login'); 
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          if (response.status === 200) {
            commit("loginSuccess", response.data.user);
            return Promise.resolve(response);
          } else {
            commit("loginFailure");
            return Promise.reject(response);
          }
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    otpresend({ commit }, user) {
      return AuthService.otpResend(user).then(
        () => {
          commit("registerSuccess", user);
          commit("setUserVerifyData", user);
          setTimeout(() => {
            router.push({ name: "otpverification" });
          }, 100);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    storeUserVerifyData({ commit }, user) {
      commit("setUserVerifyData", user);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.user = user;
    },
    loginFailure(state) {
      state.user = null;
    },
    logout(state) {
      state.user = null;
    },
    registerSuccess(state, user) {
      state.user = user;
    },
    registerFailure(state) {
      state.user = null;
    },
    setUserVerifyData(state, user) {
      localStorage.setItem("userVerifyData", JSON.stringify(user));
      state.userVerifyData = user;
    },
  },
};
